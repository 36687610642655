import React from "react";
import {
	Box,
	AppBar,
	Toolbar,
	Typography,
	Button,
	IconButton,
	MenuItem,
	Menu,
	CardMedia,
	useScrollTrigger,
	Slide,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { AccountCircle } from "@mui/icons-material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"; // Fixed the import
import { Link } from "react-router-dom";
import banner from "../static/img/Scholarship_2023-2026-min.jpg";
import * as authActions from "../store/actions/auth";
import PropTypes from "prop-types";
import { envName } from "../env";

function HideOnScroll(props) {
	const { children } = props;
	const trigger = useScrollTrigger(undefined);

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

function Header(props) {
	const { hideImage, username } = props;

	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box>
			{envName !== "prod" && (
				<Box
					sx={{
						position: "fixed",
						top: 0,
						left: 0,
						width: "100%",
						backgroundColor:
							envName === "dev"
								? "rgba(0, 128, 0, 1)"
								: "rgba(255, 165, 0, 1)",
						color: "white",
						textAlign: "center",
						py: 0.2,
						zIndex: 9999, // Ensure it stays above other content
					}}
				>
					<Typography
						variant="body2"
						sx={{ fontWeight: "bold", letterSpacing: 1 }}
					>
						{envName === "dev"
							? "PRODUCTION ENVIRONMENT"
							: "DEVELOPMENT ENVIRONMENT"}
					</Typography>
				</Box>
			)}
			<HideOnScroll {...props}>
				<AppBar>
					<Toolbar>
						<Box
							sx={{
								flexGrow: 1,
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<Button
								component={Link}
								to="/"
								sx={{ color: "white" }}
							>
								<Typography variant="h6">HOME</Typography>
							</Button>

							<Box>
								<IconButton
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={handleMenu}
									color="inherit"
									size="small"
								>
									<AccountCircle />
									{username && (
										<Typography variant="h7">
											{"\u00A0" + username}
										</Typography>
									)}
								</IconButton>
								<Menu
									id="menu-appbar"
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									keepMounted
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									open={open}
									onClose={handleClose}
								>
									<MenuItem
										onClick={() => {
											dispatch(authActions.logout());
										}}
									>
										Logout
									</MenuItem>
								</Menu>
							</Box>
						</Box>
					</Toolbar>
				</AppBar>
			</HideOnScroll>
			<Toolbar />
			{hideImage && (
				<Box>
					<CardMedia
						component="img"
						alt="SVGA banner"
						image={banner}
						title="Contemplative Reptile"
						sx={{
							width: "60%",
							mx: "auto",
							"@media (max-width: 1280px)": {
								width: "100%",
							},
						}}
					/>
				</Box>
			)}
		</Box>
	);
}

Header.propTypes = {
	hideImage: PropTypes.bool.isRequired,
	username: PropTypes.string,
};

export default Header;
