export const envName = "prod";

const getEnvironment = () => {
	if (envName.startsWith("prod")) {
		// matches prod-v1, prod-v2, prod-v3
		return {
			envName: "PRODUCTION",
			FIREBASE_URL:
				"https://svga-web-portal-default-rtdb.asia-southeast1.firebasedatabase.app/",
			SERVER_URL: "https://app.svgaindia.org",
			SERVER_URL_IMG: "https://app.svgaindia.org",
			firebaseConfig: {
				apiKey: "AIzaSyC2F2TbQKkbHjvy96h6BzYU2_xLiW6vNzI",
				authDomain: "svga-web-portal.firebaseapp.com",
				databaseURL:
					"https://svga-web-portal-default-rtdb.asia-southeast1.firebasedatabase.app",
				projectId: "svga-web-portal",
				storageBucket: "svga-web-portal.appspot.com",
				messagingSenderId: "36373262671",
				appId: "1:36373262671:web:187880244ec6575fdc553a",
			},
		}; // prod env settings
	}
	if (envName.startsWith("dev")) {
		return {
			envName: "PRODUCTION",
			FIREBASE_URL:
				"https://svga-web-portal-default-rtdb.asia-southeast1.firebasedatabase.app/",
			SERVER_URL: "http://localhost:4000",
			SERVER_URL_IMG: "http://localhost:4000",
			firebaseConfig: {
				apiKey: "AIzaSyC2F2TbQKkbHjvy96h6BzYU2_xLiW6vNzI",
				authDomain: "svga-web-portal.firebaseapp.com",
				databaseURL:
					"https://svga-web-portal-default-rtdb.asia-southeast1.firebasedatabase.app",
				projectId: "svga-web-portal",
				storageBucket: "svga-web-portal.appspot.com",
				messagingSenderId: "36373262671",
				appId: "1:36373262671:web:187880244ec6575fdc553a",
			},
		};
	} else {
		// assume any other release channel is development
		return {
			envName: "UAT",
			FIREBASE_URL:
				"https://svga-web-portal-beta-default-rtdb.asia-southeast1.firebasedatabase.app/",
			// SERVER_URL: "https://beta.svgaindia.org",
			SERVER_URL: "http://localhost:4000",
			SERVER_URL_IMG: "http://localhost:4000",
			firebaseConfig: {
				apiKey: "AIzaSyDhQ7xXp_aO-yPAh2VZKeoraf4UBInmYDY",
				authDomain: "svga-web-portal-beta.firebaseapp.com",
				databaseURL:
					"https://svga-web-portal-beta-default-rtdb.asia-southeast1.firebasedatabase.app",
				projectId: "svga-web-portal-beta",
				storageBucket: "svga-web-portal-beta.appspot.com",
				messagingSenderId: "102425427924",
				appId: "1:102425427924:web:ac886ef49a91ae318ea041",
			},
		}; // stage env settings
	}
};

export default getEnvironment;
