import { FIREBASE_URL, SERVER_URL } from "../../config";
import { ProfileField, Applicant, PaymentHandler } from "@anginn/flexi-form";
import { LOGOUT } from "./auth";

export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const SET_PAYMENTS = "SET_PAYMENTS";
export const SET_USER = "SET_USER";

export const updateTemplate = (key, template, formId, isSubmit) => {
	console.log("updateTemplate");
	return async (dispatch, getState) => {
		const applicant = getState().applicant.applicant;
		applicant.updateApplicationTemplate(key, template, formId, isSubmit);
		const ACCESS_TOKEN = getState().auth.token;
		applicant.performNext(ACCESS_TOKEN);
		const applicant_id = applicant.id;
		if (isSubmit) {
			console.log("Sending an email");
			let response = await fetch(
				`${SERVER_URL}/api/emailTemplate/${applicant_id}/${formId}`
			);

			if (!response.ok) {
				dispatch({ type: LOGOUT });
			}
		}
		try {
			const response = await fetch(
				`${SERVER_URL}/api/addMapping/${applicant_id}/${formId}`
			);
			if (!response.ok) {
				dispatch({ type: LOGOUT });
			}
		} catch (err1) {
			console.log(err1);
		}

		try {
			dispatch({ type: UPDATE_TEMPLATE, applicant });
		} catch (err) {
			throw err;
		}
	};
};

export const fetchApplicant = (id, contact, token = undefined) => {
	console.log("fetchApplicant");
	return async (dispatch, getState) => {
		if (id != null) {
			// console.log(`${FIREBASE_URL}applicants/${id}.json`);
			let ACCESS_TOKEN = token;
			if (ACCESS_TOKEN === undefined) {
				ACCESS_TOKEN = getState().auth.token;
			}
			if (ACCESS_TOKEN == null) {
				return;
			}
			const response = await fetch(
				`${FIREBASE_URL}applicants/${id}.json?auth=${ACCESS_TOKEN}`
			);

			if (!response.ok) {
				dispatch({ type: LOGOUT });
			}

			let resData = await response.json();
			const applicant = Object.assign(new Applicant(), resData);
			applicant.id = id;
			applicant.aadhaar_no = id;
			applicant.contact = contact;
			// applicant.applications = {}; // assume to be blank
			applicant.parseObject();

			const keys = Object.keys(resData);

			if (keys.length === 1 && resData.hasOwnProperty("emailOTP")) {
				resData = null;
			}

			if (resData == null) {
				const profile = {
					phone_number: new ProfileField(
						"phone_number",
						"Phone Number",
						parseInt(contact),
						false,
						undefined,
						true
					),
					aadhar_number: new ProfileField(
						"aadhar_number",
						"User ID",
						parseInt(id),
						false,
						undefined,
						true
					),
				};
				applicant.profile = profile;
				applicant.performNext(ACCESS_TOKEN);
			}
			dispatch({ type: UPDATE_TEMPLATE, applicant });
		}
	};
};

export const initialPayments = (
	paymentHandler,
	transactions,
	allowMultipleTransactions,
	aadharNo,
	formId,
	amount,
	basicInfo
) => {
	console.log("initialPayments");
	return async (dispatch, getState) => {
		try {
			const applicant = getState().applicant.applicant;
			const ACCESS_TOKEN = getState().auth.token;

			if (!paymentHandler) {
				paymentHandler = new PaymentHandler(
					transactions,
					allowMultipleTransactions
				);
			}

			let txn = paymentHandler.getLastPendingTransaction();
			if (!txn) {
				txn = paymentHandler.createTransaction(
					aadharNo,
					formId,
					amount,
					"INR",
					basicInfo
				);
			}

			applicant.updatePayments(
				aadharNo,
				formId,
				paymentHandler,
				ACCESS_TOKEN
			);

			dispatch({
				type: SET_PAYMENTS,
				payments: paymentHandler,
				transaction: txn,
			});
		} catch (err1) {
			console.log(err1);
		}
	};
};

export const placePaymentOrder = (transactionId, aadharNo, formId, amount) => {
	console.log("placePaymentOrder");
	return async (dispatch, getState) => {
		try {
			const applicant = getState().applicant.applicant;
			const ACCESS_TOKEN = getState().auth.token;

			const response = await fetch(`${SERVER_URL}/api/razorpay/order`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					aadharNo,
					formId,
					amount,
					receipt: "13532rfw",
				}),
			});
			if (!response.ok) {
				dispatch({ type: LOGOUT });
			}
			const resData = await response.json();

			const paymentHandler = getState().applicant.payments;

			paymentHandler.updatePlacedOrder(transactionId, resData);

			let txn = paymentHandler.getLastPendingTransaction();

			applicant.updatePayments(
				aadharNo,
				formId,
				paymentHandler,
				ACCESS_TOKEN
			);

			dispatch({
				type: SET_PAYMENTS,
				payments: paymentHandler,
				transaction: txn,
			});
		} catch (err1) {
			console.log(err1);
		}
	};
};

export const checkOutSuccessCallback = (
	transactionId,
	checkoutResponse,
	aadharNo,
	formId
) => {
	console.log("checkOutSuccessCallback");
	return async (dispatch, getState) => {
		try {
			const applicant = getState().applicant.applicant;
			const ACCESS_TOKEN = getState().auth.token;

			const paymentHandler = getState().applicant.payments;

			paymentHandler.updateCheckOutSuccess(
				transactionId,
				checkoutResponse
			);

			const response = await fetch(`${SERVER_URL}/api/razorpay/verify`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					razorpay_order_id: checkoutResponse.razorpay_order_id,
					razorpay_payment_id: checkoutResponse.razorpay_payment_id,
					razorpay_signature: checkoutResponse.razorpay_signature,
				}),
			});

			const resData = await response.json();

			if (resData) {
				paymentHandler.updateVerificationSuccess(
					transactionId,
					resData
				);
			} else {
				paymentHandler.updateVerificationFailure(
					transactionId,
					resData
				);
			}

			let txn = paymentHandler.getLastPendingTransaction();

			applicant.updatePayments(
				aadharNo,
				formId,
				paymentHandler,
				ACCESS_TOKEN
			);

			dispatch({
				type: SET_PAYMENTS,
				payments: paymentHandler,
				transaction: txn,
			});
		} catch (err1) {
			console.log(err1);
		}
	};
};

export const checkOutFailureCallback = (
	transactionId,
	checkoutResponse,
	aadharNo,
	formId
) => {
	console.log("checkOutFailureCallback");
	return async (dispatch, getState) => {
		try {
			const applicant = getState().applicant.applicant;
			const ACCESS_TOKEN = getState().auth.token;

			const paymentHandler = getState().applicant.payments;

			paymentHandler.updateCheckOutFailure(
				transactionId,
				checkoutResponse
			);

			let txn = paymentHandler.getLastPendingTransaction();

			applicant.updatePayments(
				aadharNo,
				formId,
				paymentHandler,
				ACCESS_TOKEN
			);

			dispatch({
				type: SET_PAYMENTS,
				payments: paymentHandler,
				transaction: txn,
			});
		} catch (err1) {
			console.log(err1);
		}
	};
};

export const resetTransaction = () => {
	console.log("resetTransaction");
	return async (dispatch, getState) => {
		try {
			const paymentHandler = getState().applicant.payments;
			dispatch({
				type: SET_PAYMENTS,
				payments: paymentHandler,
				transaction: undefined,
			});
		} catch (err1) {
			console.log(err1);
		}
	};
};

export const setUser = (id) => {
	return async (dispatch, getState) => {
		dispatch({ type: SET_USER, id });
	};
};
