import { PaymentHandler } from "@anginn/flexi-form";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as applicantActions from "../../store/actions/applicant";
import RazorpayButton from "./RazorpayButton";
import { Box, Typography } from "@mui/material";
import HourglassBottomTwoToneIcon from "@mui/icons-material/HourglassBottomTwoTone";
import AutorenewTwoToneIcon from "@mui/icons-material/AutorenewTwoTone";
import AirTwoToneIcon from "@mui/icons-material/AirTwoTone";
import ThumbUpAltTwoToneIcon from "@mui/icons-material/ThumbUpAltTwoTone";

const PostFinalSubmitPaymentHandler = (props) => {
	const { application } = props;

	const applicant = useSelector((state) => state.applicant.applicant);
	const forms = useSelector((state) => state.forms);

	const [canCheckOut, setCanCheckOut] = useState(false);

	const paymentHandler = useSelector((state) => state.applicant.payments);

	const transaction = useSelector((state) => state.applicant.transaction);

	const aadharNo = applicant.aadhaar_no;
	const formId = application.id;
	let amount = 100;

	const basicInfo = {};
	const ids = ["name", "lastName", "phone_number", "email"];
	for (const item of ids) {
		try {
			basicInfo[item] = application.getTemplate(0).getField(item).value;
		} catch (err) {}
	}

	const transactionInfo = {
		name: basicInfo?.name + " " + basicInfo?.lastName,
		contact: basicInfo?.phone_number,
		email: basicInfo?.email,
	};

	const dispatch = useDispatch();

	let isApproved = false;

	if (application.approvals) {
		isApproved = application.approvals.isApproved();
	} else if (application.approvals === undefined) {
		isApproved = true;
	}

	// Initialize PaymentHandler if undefined
	useEffect(() => {
		if (isApproved && paymentHandler === undefined) {
			dispatch(
				applicantActions.initialPayments(
					application?.payments,
					[],
					false,
					aadharNo,
					formId,
					amount,
					transactionInfo
				)
			);
		}
		// setTransaction(pendingTransaction); // Set state to trigger a re-render
	}, [paymentHandler, dispatch]);

	// Handle order placement and checkout state
	useEffect(() => {
		if (isApproved && !!paymentHandler && transaction) {
			if (transaction.canPlaceOrder()) {
				dispatch(
					applicantActions.placePaymentOrder(
						transaction.transactionId,
						aadharNo,
						formId,
						amount
					)
				);
			}
		}
	}, [paymentHandler, transaction, dispatch]);

	if (!isApproved) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<HourglassBottomTwoToneIcon
					sx={{ fontSize: 60, color: "green", mb: 2 }} // Styling for the symbol
				/>
				<Typography component="h1" variant="h4">
					Awaiting Approval
				</Typography>
			</Box>
		);
	}

	if (!paymentHandler) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<AutorenewTwoToneIcon
					sx={{ fontSize: 60, color: "green", mb: 2 }} // Styling for the symbol
				/>
				<Typography component="h1" variant="h4">
					Loading Payments
				</Typography>
			</Box>
		);
	}

	if (!!transaction && transaction.isCompleted()) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<AirTwoToneIcon
					sx={{ fontSize: 60, color: "green", mb: 2 }} // Styling for the symbol
				/>
				<Typography component="h1" variant="h4">
					Payments Complete!!!
				</Typography>
			</Box>
		);
	}

	if (!!transaction && transaction.isFailed()) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<AirTwoToneIcon
					sx={{ fontSize: 60, color: "green", mb: 2 }} // Styling for the symbol
				/>
				<Typography component="h1" variant="h4">
					Payments Failed!!!
				</Typography>
			</Box>
		);
	}

	const checkOutSuccessCallback = (response) => {
		setCanCheckOut(false);

		if (transaction.canCheckOut()) {
			dispatch(
				applicantActions.checkOutSuccessCallback(
					transaction.transactionId,
					response,
					aadharNo,
					formId
				)
			);
		}

		// dispatch(applicantActions.resetTransaction());
	};

	const checkOutFailureCallback = (response) => {
		setCanCheckOut(false);

		dispatch(
			applicantActions.checkOutFailureCallback(
				transaction.transactionId,
				response,
				aadharNo,
				formId
			)
		);
	};

	if (!!transaction && transaction.canCheckOut()) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<ThumbUpAltTwoToneIcon
					sx={{ fontSize: 60, color: "green", mb: 2 }} // Styling for the symbol
				/>
				<Typography component="h1" variant="h4">
					Approved
				</Typography>
				<br></br>
				<RazorpayButton
					transaction={transaction}
					checkOutSuccessCallback={checkOutSuccessCallback}
					updateCheckOutFailure={checkOutFailureCallback}
				/>
			</Box>
		);
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
			}}
		>
			<AutorenewTwoToneIcon
				sx={{ fontSize: 60, color: "green", mb: 2 }} // Styling for the symbol
			/>
			<Typography component="h1" variant="h4">
				Fetching Details
			</Typography>
		</Box>
	);
};

export { PostFinalSubmitPaymentHandler };
// Try some other approach, still transaction object seems to be stale
