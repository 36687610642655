import moment, { Moment } from "moment";
import { FIREBASE_URL, LOGIN_USER, SERVER_URL } from "../config";
import {
	APPROVE_STATE,
	ApproverResponse,
	ApproversResponses,
} from "./ApproversResponses";
import { PaymentHandler } from "./PaymentHandler";

class ApplicationFormValues {
	constructor(
		public id: string,
		public values: { [key: string]: string } = {},
		public eligibility?: any,
		public isActive: boolean = true,
		public submitted: boolean = false,
		public submittedOn?: Moment,
		public activeStep: number = 0,
		createdOn?: Moment,
		public approvalResponses: ApproversResponses = new ApproversResponses(
			{}
		), // Initialize with an empty ApproversResponses object
		public isEditableByApplicant: boolean = true, // Added isEditableByApplicant flag
		public changeLog: Array<{
			fieldId: string;
			previousValue: string;
			newValue: string;
			timestamp: string;
		}> = [],
		public auditLog: Array<{
			property: string;
			previousValue: any;
			newValue: any;
			timestamp: string;
		}> = [],
		public payments?: PaymentHandler
	) {
		if (createdOn === undefined) {
			this.createdOn = moment();
		} else {
			this.createdOn = createdOn;
		}
	}

	createdOn: Moment;

	parseObject() {
		let approverResponses_obj = Object.assign(
			new ApproversResponses({}),
			this.approvalResponses
		);
		this.approvalResponses = approverResponses_obj;
		this.approvalResponses.parseObject();
		if (this.payments) {
			this.payments = PaymentHandler.fromJSON(this.payments);
		}
	}

	async addApproverResponse(
		applicantId: string,
		approverResponseObj: ApproverResponse,
		isEditingUponDeclineCurrentLeaf: boolean
	) {
		this.updateApproversResponse(approverResponseObj);

		// Check if the response is declined and editing on decline is allowed
		if (
			approverResponseObj.status === APPROVE_STATE.DENIED.value &&
			isEditingUponDeclineCurrentLeaf
		) {
			this.allowEditUponDecline();
			await fetch(
				`${FIREBASE_URL}applicants/${applicantId}/applications_values/${this.id}.json?auth=${LOGIN_USER.token}`,
				{
					method: "PATCH",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						submitted: this.submitted,
						isEditableByApplicant: this.isEditableByApplicant,
						auditLog: this.auditLog,
					}),
				}
			);
		} else {
			this.updateAuditLog(applicantId);
		}

		let response = await fetch(
			`${FIREBASE_URL}applicants/${applicantId}/applications_values/${this.id}/approvalResponses/responses.json?auth=${LOGIN_USER.token}`,
			{
				method: "PATCH",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					[approverResponseObj.leafId]: approverResponseObj,
				}),
			}
		);
		if (response.ok) {
			response = await fetch(
				`${SERVER_URL}/api/updateApprovalsMapping/${applicantId}/${this.id}`
			);
			if (!response.ok) {
				// Handle error
			}
		}
	}

	updateApproversResponse(approversResponse: ApproverResponse) {
		const previousResponse =
			this.approvalResponses.responses[approversResponse.leafId];
		this.approvalResponses.updateApproversResponse(approversResponse);
		this.logAudit("approvalResponses", previousResponse, approversResponse);
	}

	submit() {
		this.logAudit("submitted", this.submitted, true);
		this.logAudit("submittedOn", this.submittedOn, moment());
		this.logAudit(
			"isEditableByApplicant",
			this.isEditableByApplicant,
			false
		);

		this.submitted = true;
		this.submittedOn = moment();
		this.isEditableByApplicant = false;
	}

	allowEditUponDecline() {
		this.logAudit("submitted", this.submitted, false);
		this.logAudit(
			"isEditableByApplicant",
			this.isEditableByApplicant,
			true
		);

		this.submitted = false;
		this.isEditableByApplicant = true;
	}

	updateValues(newValues: { [key: string]: string }) {
		for (const [fieldId, newValue] of Object.entries(newValues)) {
			this.updateValue(fieldId, newValue);
		}
	}

	updateValue(fieldId: string, newValue: string) {
		const previousValue = this.values[fieldId];
		this.values[fieldId] = newValue;
		if (
			previousValue !== "" &&
			previousValue !== undefined &&
			newValue !== "" &&
			newValue !== undefined &&
			previousValue !== newValue
		) {
			const change = {
				fieldId,
				previousValue,
				newValue,
				timestamp: moment().toISOString(),
			};
			this.changeLog.push(change);
		}
	}

	setPayments(payments: PaymentHandler) {
		this.payments = payments;
	}

	getChangeLog() {
		return this.changeLog;
	}

	getAuditLog() {
		return this.auditLog;
	}

	private logAudit(property: string, previousValue: any, newValue: any) {
		const auditEntry = {
			property,
			previousValue,
			newValue,
			timestamp: moment().toISOString(),
		};
		this.auditLog.push(auditEntry);
	}

	private async updateAuditLog(applicantId: string) {
		let response = await fetch(
			`${FIREBASE_URL}/applicants/${applicantId}/applications_values/${this.id}.json?auth=${LOGIN_USER.token}`,
			{
				method: "PATCH",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ auditLog: this.auditLog }),
			}
		);
		if (response.ok) {
		}
	}
}

export { ApplicationFormValues };
