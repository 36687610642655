import moment, { Moment } from "moment";

interface TransactionResponse {
	[key: string]: any; // Generic structure for response payloads
}

interface BasicInfo {
	[key: string]: any; // Generic structure for response payloads
}

class PaymentTransaction {
	transactionId: string;
	applicantId: string;
	formId: string;
	orderId?: string; // Optional until updated
	amount: number;
	currency: string;
	info: BasicInfo;
	status:
		| "INITIATED"
		| "ORDER_PLACE"
		| "VERIFICATION"
		| "COMPLETED"
		| "FAILED";
	createdOn: Moment;
	updatedOn: Moment;
	orderResponse: TransactionResponse;
	checkOutResponse: TransactionResponse;
	verificationResponse: TransactionResponse;

	private static validTransitions: { [key: string]: string[] } = {
		INITIATED: ["ORDER_PLACE", "FAILED"],
		ORDER_PLACE: ["VERIFICATION", "FAILED"],
		VERIFICATION: ["COMPLETED", "FAILED"],
		COMPLETED: [],
		FAILED: [],
	};

	constructor(
		transactionId: string,
		applicantId: string,
		formId: string,
		amount: number,
		currency: string,
		info: BasicInfo,
		orderResponse: TransactionResponse = {},
		checkOutResponse: TransactionResponse = {},
		verificationResponse: TransactionResponse = {},
		createdOn: Moment = moment(),
		updatedOn: Moment = moment(),
		status:
			| "INITIATED"
			| "ORDER_PLACE"
			| "VERIFICATION"
			| "COMPLETED"
			| "FAILED" = "INITIATED",
		orderId?: string
	) {
		if (amount <= 0) throw new Error("Amount must be a positive number.");
		if (!/^[A-Z]{3}$/.test(currency))
			throw new Error("Currency must be a valid ISO 4217 code.");

		this.transactionId = transactionId;
		this.applicantId = applicantId;
		this.formId = formId;
		this.amount = amount;
		this.currency = currency;
		this.info = info;
		this.status = status;
		this.createdOn = createdOn;
		this.updatedOn = updatedOn;
		this.orderResponse = orderResponse;
		this.checkOutResponse = checkOutResponse;
		this.verificationResponse = verificationResponse;
		this.orderId = orderId;
	}

	updatePlacedOrder(response: TransactionResponse): void {
		this.orderResponse = response;
		this.orderId = response?.id;
		this.updatedOn = moment();
		this.setStatus("ORDER_PLACE");
	}

	updateCheckOutSuccess(response: TransactionResponse): void {
		this.checkOutResponse = response;
		this.updatedOn = moment();
		this.setStatus("VERIFICATION");
	}

	updateCheckOutFailure(response: TransactionResponse): void {
		this.checkOutResponse = response;
		this.updatedOn = moment();
		this.setStatus("FAILED");
	}

	updateVerificationSuccess(response: TransactionResponse): void {
		this.verificationResponse = response;
		this.updatedOn = moment();
		this.setStatus("COMPLETED");
	}

	updateVerificationFailure(response: TransactionResponse): void {
		this.verificationResponse = response;
		this.updatedOn = moment();
		this.setStatus("FAILED");
	}

	setStatus(
		newStatus:
			| "INITIATED"
			| "ORDER_PLACE"
			| "VERIFICATION"
			| "COMPLETED"
			| "FAILED"
	): void {
		if (
			!PaymentTransaction.validTransitions[this.status].includes(
				newStatus
			)
		) {
			throw new Error(
				`Cannot transition from ${this.status} to ${newStatus}.`
			);
		}
		this.status = newStatus;
	}

	canPlaceOrder(): boolean {
		return this.status === "INITIATED" && !this.orderId;
	}

	canCheckOut(): boolean {
		return this.status === "ORDER_PLACE" && !!this.orderId;
	}

	canVerify(): boolean {
		return this.status === "VERIFICATION";
	}

	isCompleted(): boolean {
		return this.status === "COMPLETED";
	}

	isFailed(): boolean {
		return this.status === "FAILED";
	}

	toJSON(): object {
		return {
			transactionId: this.transactionId,
			applicantId: this.applicantId,
			formId: this.formId,
			orderId: this.orderId,
			amount: this.amount,
			currency: this.currency,
			info: this.info,
			status: this.status,
			createdOn: this.createdOn.toISOString(),
			updatedOn: this.updatedOn.toISOString(),
			orderResponse: this.orderResponse,
			checkOutResponse: this.checkOutResponse,
			verificationResponse: this.verificationResponse,
		};
	}

	static fromJSON(json: any): PaymentTransaction {
		return new PaymentTransaction(
			json.transactionId,
			json.applicantId,
			json.formId,
			json.amount,
			json.currency,
			json.info,
			json.orderResponse,
			json.checkOutResponse,
			json.verificationResponse,
			moment(json.createdOn),
			moment(json.updatedOn),
			json.status,
			json?.orderId
		);
	}

	hash() {
		const jsonString = JSON.stringify(this, Object.keys(this).sort()); // Sort keys for consistent hashing
		let hash = 0;

		for (let i = 0; i < jsonString.length; i++) {
			const char = jsonString.charCodeAt(i);
			hash = (hash << 5) - hash + char;
			hash |= 0; // Convert to a 32-bit integer
		}

		return `hash_${hash}`;
	}
}

export { PaymentTransaction, TransactionResponse, BasicInfo };
